<template>
  <div class="pagination">
    <v-btn icon :disabled="isFirst" :ripple="false" @click="firstPage()">
      <v-icon>mdi-page-first</v-icon>
    </v-btn>
    <v-btn icon :disabled="isFirst" :ripple="false" @click="prevPage()">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-text-field
      hide-details="true"
      :value="currentPage"
      @change="onChange($event)"
    ></v-text-field>
    <div class="total-pages">de {{ totalPages }}</div>
    <v-btn icon :disabled="isLast" :ripple="false" @click="nextPage()">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
    <v-btn icon :disabled="isLast" :ripple="false" @click="lastPage()">
      <v-icon>mdi-page-last</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  computed: {
    currentPage: function () {
      return this.validateValue(this.value);
    },
    totalPages: function () {
      return this.length > 0 ? this.length : 1;
    },
    isFirst: function () {
      return this.value === 1;
    },
    isLast: function () {
      return this.value === this.totalPages;
    },
  },
  methods: {
    validateValue: function (value) {
      if (value > this.totalPages) {
        return this.totalPages;
      } else if (value < 1) {
        return 1;
      } else {
        return value;
      }
    },
    onChange: function (nextValue) {
      this.$emit("change", this.validateValue(nextValue));
    },
    firstPage: function () {
      this.onChange(1);
    },
    prevPage: function () {
      this.onChange(this.value - 1);
    },
    nextPage: function () {
      this.onChange(this.value + 1);
    },
    lastPage: function () {
      this.onChange(this.totalPages);
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Number,
      default: 1,
      required: true,
    },
    length: {
      type: Number,
      default: 1,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  align-items: center;
  display: flex;
  max-width: 250px;

  .total-pages {
    width: 110px;
  }

  .v-btn--icon .v-icon {
    color: #1C1C44;
  }

  .v-text-field {
    padding-top: unset;
    margin-top: unset;
  }
}
</style>
>
